import React from "react";
import ProdLayout from "../../prodlayout";
import pi1 from "./p1.jpg";
import pi2 from "./p2.jpg";
import pi3 from "./p3.jpg";
import pi4 from "./p4.jpg";
const data = {
  images: [pi1, pi2, pi3, pi4],
  badge: "Solid",
  sku: "TPK162",
  availability: "In stock",
  title: "OCSO Solid Men's T-shirt TPK162",
  mrpPrice: "1720",
  sellPrice: "799",
  disc:
    "OCSO Men's Grey solid T-shirt,With full sleeves, Best 5 star Flipkart selling product",
};
export default () => {
  return <ProdLayout data={data} />;
};
